import { SearchFilters as SearchFiltersType } from "@outschool/gql-backend-generated";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { useTrackEvent } from "@outschool/ui-analytics";
import isEqual from "lodash/isEqual";
import mapKeys from "lodash/mapKeys";
import React from "react";
import { v4 } from "uuid";

export default function useTrackSearchFiltersApplied() {
  const trackEvent = useTrackEvent();
  const [searchUid, setSearchUid] = React.useState(v4());

  const trackSearchFiltersApplied = React.useCallback(
    ({
      filterCategory,
      oldFilters,
      newFilters,
    }: {
      filterCategory?: string;
      oldFilters: SearchFiltersType;
      newFilters: SearchFiltersType;
    }) => {
      if (!isEqual(oldFilters, newFilters)) {
        const nextSearchUid = v4();
        trackEvent("Search filters applied", {
          filterCategory,
          ...mapKeys(oldFilters, (_value, key) => `old_${key}`),
          ...mapKeys(newFilters, (_value, key) => `new_${key}`),
          old_searchUid: searchUid,
          new_searchUid: nextSearchUid,
        });
        setSearchUid(nextSearchUid);
      }
    },
    [searchUid, trackEvent]
  );

  return {
    searchUid,
    trackSearchFiltersApplied,
  };
}
