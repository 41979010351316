import { ErrorMessage } from "@outschool/ui-components-shared";
import { useTopNoticeContext } from "@outschool/ui-components-website";
import { Banner } from "@outschool/ui-legacy-component-library";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { useEffect } from "react";

type Props = {
  topNotice?: string;
};

export default function TopNoticeBar({ topNotice: propTopNotice }: Props) {
  const { setTopNotice, topNotice: appTopNotice } = useTopNoticeContext();
  useEffect(() => {
    setTopNotice(null);
  }, [setTopNotice]);
  const topNotice = propTopNotice || appTopNotice;
  return topNotice ? (
    <Banner sticky>
      <ErrorMessage value={topNotice} />
    </Banner>
  ) : null;
}
