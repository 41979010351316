import { Modal } from "@outschool/ui-legacy-component-library";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import React from "react";

import TopNoticeBar from "./TopNoticeBar";

function PageModal({ children, ...props }) {
  return (
    <Modal {...props}>
      <TopNoticeBar />
      {children}
    </Modal>
  );
}

export default PageModal;
