import { getReferrerAttribution } from "@outschool/attribution";
import {
  LoginOrCreateAccountWithAppleV2Mutation as LoginOrCreateAccountWithAppleV2MutationType,
  LoginOrCreateAccountWithAppleV2MutationVariables,
} from "@outschool/gql-frontend-generated";
import { useApolloClient } from "@outschool/ui-apollo";
import { loginOrCreateAccountWithAppleMutationV2 } from "@outschool/ui-auth";
import React from "react";
import type { AnalyticsInterface } from "@outschool/ui-analytics";
import type { FetchResult } from "@outschool/ui-apollo";

export function useLoginWithAppleMutationV2(): (
  analytics: AnalyticsInterface,
  { variables }
) => Promise<
  FetchResult<
    LoginOrCreateAccountWithAppleV2MutationType,
    Record<string, any>,
    Record<string, any>
  >
> {
  const client = useApolloClient();
  return React.useCallback(
    async (analytics: AnalyticsInterface, { variables }) => {
      const response = await client.mutate<
        LoginOrCreateAccountWithAppleV2MutationType,
        LoginOrCreateAccountWithAppleV2MutationVariables
      >({
        mutation: loginOrCreateAccountWithAppleMutationV2,
        variables: {
          ...variables,
          osRef: {
            ...getReferrerAttribution(),
            anonymousId: await analytics.anonymousId(),
          },
        },
      });

      return response;
    },
    [client]
  );
}
