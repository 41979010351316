import { Menu, MenuItem, styled } from "@outschool/backpack";
import { faChevronDown } from "@outschool/icons";
import { SUPPORTED_LANGUAGES, useLocale } from "@outschool/localization";
import { useImpressionTracking } from "@outschool/ui-legacy-component-library";
import { useRouter } from "next/router";
import React, { useCallback, useRef, useState } from "react";

import { HeaderNavLabel } from "./DesktopHeaderNav";
import HeaderNavItem from "./HeaderNavItem";

const languageOptions = SUPPORTED_LANGUAGES.map(language => ({
  value: language.i18nLocale,
  label: language.localLanguageName,
}));
type LanguageOptionType = (typeof languageOptions)[number];

// Custom styling for dropdown menu
const StyledMenu = styled((props: any) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color: theme.palette.text.primary,
  },
}));

const LanguageSelector: React.FC = () => {
  const locale = useLocale();
  const router = useRouter();
  const languageSelectorNodeRef = useRef<HTMLDivElement>(null);
  const { trackTouch } = useImpressionTracking({
    node: languageSelectorNodeRef.current,
    trackingLabel: "header_language_selector",
    uniqueId: "header_language_selector",
  });

  const [selectedLanguage, setSelectedLanguage] = useState(() =>
    languageOptions.find(l => l.value === locale)
  );
  // Anchor element for the dropdown menu controls if it shows up or not
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // Handle menu toggle on click or keydown (Enter/Space)
  const handleMenuToggle = useCallback(
    (
      event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
    ) => {
      setAnchorEl(prev => (prev ? null : (event.currentTarget as HTMLElement)));
    },
    []
  );

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleLanguageChange = useCallback(
    (selectedOption: LanguageOptionType) => {
      const newLocale = selectedOption.value;
      setSelectedLanguage(selectedOption);
      handleMenuClose();
      trackTouch({ locale: newLocale });
      router.push(router.asPath, router.asPath, { locale: newLocale });
    },
    [handleMenuClose, router, trackTouch]
  );

  return (
    <HeaderNavItem
      ref={languageSelectorNodeRef}
      onClick={handleMenuToggle}
      onKeyDown={event => {
        if (event.key === "Enter" || event.key === " ") {
          handleMenuToggle(event);
        }
      }}
      sx={{ cursor: "pointer" }}
      // eslint-disable-next-line i18next/no-literal-string
      aria-controls={anchorEl ? "language-menu" : undefined}
      aria-haspopup="true"
      // eslint-disable-next-line i18next/no-literal-string
      aria-expanded={anchorEl ? "true" : undefined}
      role="button"
      tabIndex={0}
    >
      <HeaderNavLabel
        icon={faChevronDown}
        label={selectedLanguage?.label || ""}
        iconSx={{ fontSize: "1em" }}
      />
      <StyledMenu
        id="language-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        MenuListProps={{
          // eslint-disable-next-line i18next/no-literal-string
          "aria-labelledby": "language-selector",
        }}
      >
        {languageOptions.map(option => (
          <MenuItem
            key={option.value}
            onClick={() => handleLanguageChange(option)}
            selected={option.value === selectedLanguage?.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </StyledMenu>
    </HeaderNavItem>
  );
};

export default LanguageSelector;
