import {
  OAUTH2_CAN_CREATE_USER,
  OAUTH2_CREATE_OPTIONS,
  OAUTH2_ERROR,
  OAUTH2_ON_ERROR,
  OAUTH2_ON_SUCCESS,
  OAUTH2_SESSION_TOKEN,
  decodeToken,
  isLearnerAuthTransfer,
} from "@outschool/auth-shared";
import { CreateOrLoginError } from "@outschool/gql-backend-generated";
import { useLookupIP } from "@outschool/iplookup-client";
import {
  browseRootPath,
  joinParentAppleAccountPath,
  redirectAfterLoginUrl,
} from "@outschool/routes";
import * as Time from "@outschool/time";
import { useAnalytics, useTrackEvent } from "@outschool/ui-analytics";
import {
  AppleAuthResponse,
  SupportedStrategies,
  getPostLoginAction,
  getPostLoginPath,
  setAuthStrategy,
  useOAuth2TrackingParams,
  useTokenContext,
} from "@outschool/ui-auth";
import Cookies from "js-cookie";
import React from "react";

// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { useLoginWithAppleMutationV2 } from "../../../lib/client-side-fetch/useLoginWithAppleMutation";

interface CreateParams {
  isGiftCardSignup?: boolean;
  isLeader?: boolean;
  trackingParameters?: { [key: string]: string };
}

interface AppleLoginSuccessPayload {
  sessionToken: string;
  refreshToken: string;
  postLoginPath: string;
  isLearnerTransfer: boolean;
}

export function useLoginWithApple(
  onSuccess: (payload: AppleLoginSuccessPayload) => void,
  onError: (error: CreateOrLoginError) => void,
  createParams?: CreateParams
) {
  const loginWithAppleV2Mutation = useLoginWithAppleMutationV2();
  const analytics = useAnalytics();
  const track = useTrackEvent();

  return React.useCallback(
    async (response: AppleAuthResponse) => {
      const { code } = response.authorization;
      const createOptions = {
        attribution: await analytics.attribution(),
        browserTimeZone: Time.guessBrowserTimeZone(),
        isGiftCardSignup: createParams?.isGiftCardSignup,
        isLeader: createParams?.isLeader,
      };
      const name = response.user
        ? `${response.user.name.firstName} ${response.user.name.lastName}`
        : undefined;
      const { data } = await loginWithAppleV2Mutation(analytics, {
        variables: {
          code,
          name,
          createOptions,
        },
      });
      const result = data.loginOrCreateAccountWithAppleV2;
      if (result.__typename === "LoginError") {
        onError(result.error);
      } else {
        const {
          isNewUser,
          authentication: { sessionToken, refreshToken },
        } = result;
        if (isNewUser && createParams?.trackingParameters) {
          track("signup-by-apple", createParams.trackingParameters);
        }
        const decodedToken = decodeToken(sessionToken);
        const isLearnerTransfer = isLearnerAuthTransfer(decodedToken)
          ? decodedToken.isLearnerTransfer
          : false;
        const postLoginPath = isNewUser
          ? joinParentAppleAccountPath()
          : getPostLoginPath() || browseRootPath();
        onSuccess({
          sessionToken,
          refreshToken,
          postLoginPath,
          isLearnerTransfer,
        });
      }
    },
    // TODO: Enable this lint rule and fix dependencies array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      analytics,
      track,
      onError,
      onSuccess,
      createParams?.isGiftCardSignup,
      createParams?.isLeader,
      createParams?.trackingParameters,
    ]
  );
}

export function useLoginWithRedirect(
  onError: (error: CreateOrLoginError) => void,
  createParams?: CreateParams
) {
  const analytics = useAnalytics();
  const { getTokens } = useTokenContext();
  const { setTrackingParameters } = useOAuth2TrackingParams();
  const { ipInfoLoaded, isInGDPR } = useLookupIP();

  React.useEffect(() => {
    const authError = Cookies.get(OAUTH2_ERROR);
    if (onError && authError) {
      Cookies.remove(OAUTH2_ERROR);
      onError(authError as CreateOrLoginError);
    }
  }, [onError]);

  React.useEffect(() => {
    Cookies.set(OAUTH2_ON_ERROR, window.location.href);
    Cookies.set(OAUTH2_ON_SUCCESS, redirectAfterLoginUrl());
    Cookies.set(OAUTH2_CAN_CREATE_USER, "true");
    Cookies.set(
      OAUTH2_CREATE_OPTIONS,
      JSON.stringify({
        attribution: analytics.attribution(),
        browserTimeZone: Time.guessBrowserTimeZone(),
        isGiftCardSignup: createParams?.isGiftCardSignup,
        isLeader: createParams?.isLeader,
        subscribe: ipInfoLoaded ? !isInGDPR : false,
      })
    );
    const { sessionToken } = getTokens();
    if (sessionToken) {
      Cookies.set(OAUTH2_SESSION_TOKEN, sessionToken);
    } else {
      Cookies.remove(OAUTH2_SESSION_TOKEN);
    }
    if (createParams?.trackingParameters) {
      setTrackingParameters(createParams?.trackingParameters);
    }
  }, [
    analytics,
    createParams?.isGiftCardSignup,
    createParams?.isLeader,
    createParams?.trackingParameters,
    setTrackingParameters,
    getTokens,
    ipInfoLoaded,
    isInGDPR,
  ]);

  return React.useCallback(
    (strategy: SupportedStrategies, customPostLoginAction?: string) => {
      setAuthStrategy(
        strategy,
        getPostLoginAction() || customPostLoginAction,
        getPostLoginPath()
      );
    },
    // TODO: Enable this lint rule and fix dependencies array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
}
