import { SearchFilters } from "@outschool/gql-backend-generated";
import {
  SearchItem,
  usePlaceholderSuggestions,
} from "@outschool/ui-components-website";
import { useComponentTrackingContext } from "@outschool/ui-legacy-component-library";
import { useCombobox } from "downshift";
import { useState } from "react";
import { useDebouncedCallback } from "use-debounce/lib";

import {
  useSearchSuggestions,
  useSelectInitialSearchItem,
  useSetSearchSuggestions,
} from "../ParentSearchSuggestions";

type ExtendedSearchFiltersType = SearchFilters & {
  userName?: string;
};

export function queryFromSearchItem(
  searchItem: SearchItem,
  priorFilters: Pick<ExtendedSearchFiltersType, "order">
): Pick<ExtendedSearchFiltersType, "userUid" | "userName" | "q" | "order"> {
  if (searchItem.type === "teacher") {
    return {
      q: undefined,
      userUid: searchItem.uid,
      userName: searchItem.name,
      order: "upcoming",
    };
  } else {
    return {
      q: searchItem.value || searchItem.name,
      userUid: undefined,
      userName: undefined,
      order: priorFilters.order === "upcoming" ? undefined : priorFilters.order,
    };
  }
}

export default function useSearchWithSuggestions(
  onSearchFieldChange: (item: SearchItem) => void,
  onSearchFieldCommit: (item: SearchItem) => void,
  queryOptions = { ignore: [] },
  id?: string
) {
  const [didUserFocusOnInput, setDidUserFocusOnInput] = useState(false);
  const [searchSuggestions, setSearchSuggestions] = useState<SearchItem[]>([]);

  const [debouncedFetchSuggestions] = useDebouncedCallback(options => {
    fetchSuggestions(options);
  }, 300);

  const track = useComponentTrackingContext();

  const {
    loading: loadingSuggestions,
    fetchSuggestions,
    popularTermSuggestions,
    teacherSuggestions,
    topicSuggestions,
  } = useSearchSuggestions(5, queryOptions.ignore);

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    openMenu,
    selectedItem: selectedComboboxItem,
    inputValue,
    setInputValue,
    selectItem: selectComboboxItem,
    closeMenu,
    // Beware: Omitting this generic type caused TS4.9 to crash indecipherably
  } = useCombobox<SearchItem>({
    id,
    initialInputValue: "",
    items: searchSuggestions,
    itemToString: item => {
      if (!item) {
        return "";
      }
      if (item.type === "keyword") {
        return inputValue || "";
      }
      return item.name || "";
    },
    onSelectedItemChange: ({ selectedItem, type }) => {
      if (type !== useCombobox.stateChangeTypes.FunctionSetInputValue) {
        closeMenu();
        if (!selectedItem) {
          return;
        }
        onSearchFieldCommit(selectedItem);
        if (didUserFocusOnInput && selectedItem) {
          track(
            "listings_typeahead_touch",
            {
              inputValue,
              suggestionType: selectedItem.type,
              suggestionValue: selectedItem.name,
              suggestionUid: selectedItem.uid,
              positionInList: highlightedIndex,
              experiments: {
                mobileSearch: false,
              },
            },
            {
              // Redshift only options
              integrations: {
                All: false,
              },
            }
          );
        }
      }
    },
    onInputValueChange: ({ type, inputValue }) => {
      if (type === useCombobox.stateChangeTypes.InputChange) {
        onSearchFieldChange({
          type: "keyword",
          name: inputValue,
          uid: inputValue,
        });
      }
      if (!inputValue) {
        closeMenu();
        setSearchSuggestions([]);
      } else {
        debouncedFetchSuggestions({ variables: { query: inputValue } });
      }
    },
  });

  useSetSearchSuggestions({
    // @ts-ignore TS(2322): Type 'Dispatch<SetStateAction<SearchItem[]>>' is n... Remove this comment to see the full error message
    setSearchSuggestions,
    inputValue,
    popularTermSuggestions,
    teacherSuggestions,
    topicSuggestions,
  });

  useSelectInitialSearchItem({
    // @ts-ignore TS(2322): Type '(item: import("/Users/anya/outschool/lang-js... Remove this comment to see the full error message
    selectComboboxItem,
    // @ts-ignore TS(2322): Type 'import("/Users/anya/outschool/lang-js/apps/w... Remove this comment to see the full error message
    selectedComboboxItem,
    setInputValue,
  });

  const placeholderSuggestions = usePlaceholderSuggestions();

  const onInputFocus = () => {
    setDidUserFocusOnInput(true);
    if (inputValue) {
      openMenu();
      debouncedFetchSuggestions({ variables: { query: inputValue } });
    } else {
      setSearchSuggestions(placeholderSuggestions as SearchItem[]);
      openMenu();
    }
  };

  return {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    inputValue,
    setInputValue,
    onInputFocus,
    loadingSuggestions,
    suggestionsOpen: isOpen,
    searchSuggestions,
    suggestionsHighlightedIndex: highlightedIndex,
    closeMenu,
  };
}
