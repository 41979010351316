import { Box, Theme } from "@outschool/backpack";
import {
  Footer,
  FooterLinkInfo,
  PageAlerts,
  ReadOnlyBanner,
} from "@outschool/ui-components-website";
import { useIsMounted } from "@outschool/ui-utils";
import React from "react";

import * as Env from "../../../../Env";
import { UserMode } from "../../../shared/UserMode";
import FooterLocalizationButton from "../FooterLocalizationButton";
import { Header } from "../nav/Header";
import SignupModal from "../SignupModal";
import TimedTopNoticeBar from "../TimedTopNoticeBar";
import TopNoticeBar from "../TopNoticeBar";

export type SimpleLayoutProps = {
  children?: React.ReactNode;
  error?: string;
  narrowColumn?: boolean;
  narrowPaddedColumn?: boolean;
  grayBackground?: boolean;
  hideFooter?: boolean;
  hideReviewReminder?: boolean;
  sx?: React.CSSProperties;
  contentSx?: React.CSSProperties;
  userMode?: UserMode;
  allowCategoriesNavbar?: boolean;
  footerCategories?: FooterLinkInfo[];
  tutoringCategories?: FooterLinkInfo[];
  headerComponent?: React.ReactNode;
};

const SimpleLayout = React.forwardRef<HTMLDivElement, SimpleLayoutProps>(
  (
    {
      children,
      error,
      narrowColumn,
      narrowPaddedColumn,
      grayBackground,
      hideFooter = false,
      sx = {},
      contentSx = {},
      allowCategoriesNavbar = false,
      footerCategories,
      tutoringCategories,
      headerComponent,
      ...props
    },
    ref: React.Ref<HTMLDivElement>
  ) => {
    const isMounted = useIsMounted();
    return (
      <Box
        sx={{
          minHeight: "100%",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          ...((grayBackground || narrowColumn || narrowPaddedColumn) && {
            backGroundColor: "#FAFAFA",
          }),
          ...sx,
        }}
        ref={ref}
      >
        <Box>
          {headerComponent ? (
            headerComponent
          ) : (
            <Header allowCategoriesNavbar={allowCategoriesNavbar} {...props} />
          )}
          <TopNoticeBar topNotice={error} />
          <TimedTopNoticeBar />
          {Env.IS_READ_ONLY_MODE && <ReadOnlyBanner />}
          <PageAlerts />
        </Box>
        <Box
          sx={(theme: Theme) => ({
            flexGrow: 1,
            ...(narrowColumn && {
              maxWidth: "750px",
              position: "relative",
              width: "100%",
              marginLeft: "auto",
              marginRight: "auto",
              boxSizing: "border-box",
              backgroundColor: "#FFF",
            }),
            ...(narrowPaddedColumn && {
              maxWidth: "750px",
              position: "relative",
              width: "100%",
              marginLeft: "auto",
              marginRight: "auto",
              boxSizing: "border-box",
              backgroundColor: "#FFF",
              paddingY: "2em",
              paddingX: "1em",
              [theme.breakpoints.up("md")]: {
                paddingX: "2em",
              },
            }),
            ...contentSx,
          })}
        >
          {children}
        </Box>
        {!hideFooter && (
          <Footer
            isInCA={true}
            LocalizationButton={<FooterLocalizationButton />}
            randomCategories={footerCategories}
            tutoringCategories={tutoringCategories}
          />
        )}
        {isMounted && <SignupModal />}
      </Box>
    );
  }
);

export default SimpleLayout;
