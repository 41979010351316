import { SearchBoxComponentType } from "@outschool/backpack";
import { useTranslation } from "@outschool/localization";
import React from "react";

import { useSearchFilters } from "./useSearchFilters";

export default function ClassSearchForm({
  query,
  setQuery,
  onSubmit,
  placeholder,
  onInputFocus = () => {},
  getInputProps = () => ({}),
  spellCheck = true,
  SearchBoxComponent,
  searchLabel,
}: {
  query: string;
  setQuery: (query: string) => void;
  onSubmit?: (query: string) => void;
  placeholder?: string;
  onInputFocus?: () => void;
  getInputProps?: () => object;
  searchLabel?: string;
  spellCheck?: boolean;
  SearchBoxComponent?: SearchBoxComponentType;
}) {
  const { t } = useTranslation("ssr-client\\components\\nav\\ClassSearchForm");
  placeholder ??= t("Any topic or teacher");
  const { clearSearchInputBoxRef, setFilters: setSearchFilters } =
    useSearchFilters();

  const inputRef = React.createRef<HTMLInputElement>();

  const handleSubmit = e => {
    e.preventDefault();

    if (inputRef.current) {
      inputRef.current.blur();
    }

    setSearchFilters("Query", {
      q: query && query.length ? query : null,
    });

    handleBlur();
  };

  const handleBlur = () => {
    if (onSubmit) {
      onSubmit(query);
    }
  };

  const handleChange = e => {
    setQuery(e.target.value);
  };

  const handleClear = () => {
    setQuery("");
  };

  return (
    <SearchBoxComponent
      onSubmit={handleSubmit}
      onClear={handleClear}
      inputProps={{
        ref: inputRef,
        placeholder,
        value: query || "",
        onFocus: onInputFocus,
        onBlur: handleBlur,
        onChange: handleChange,
        spellCheck,
        getInputProps,
      }}
      searchLabel={searchLabel || t("Search")}
      clearSearchInputBoxRef={clearSearchInputBoxRef}
      clearSearchAriaLabel={t("Clear")}
    />
  );
}
