import {
  Box,
  SearchBoxComponentType,
  visuallyHidden,
} from "@outschool/backpack";
import { SearchFilters as SearchFiltersType } from "@outschool/gql-backend-generated";
import { useTranslation } from "@outschool/localization";
import { SearchSuggestionsMenu } from "@outschool/ui-components-website";
import { useComponentTrackingContext } from "@outschool/ui-legacy-component-library";
import { SystemStyleObject } from "@styled-system/css";
import React, { useEffect } from "react";

import ClassSearchForm from "./ClassSearchForm";
import useSearchWithSuggestions, {
  queryFromSearchItem,
} from "./useSearchWithSuggestions";

type ExtendedSearchFiltersType = SearchFiltersType & {
  userName?: string;
};

type Props = {
  id?: string;
  searchPlaceholder?: string;
  searchFilters?: ExtendedSearchFiltersType;
  setSearchFilters: (filters: ExtendedSearchFiltersType) => void;
  setImplicitSearchFilters?: (filters: ExtendedSearchFiltersType) => void;
  clearDropdowns?: () => void;
  menuStyleSx?: SystemStyleObject;
  SearchBoxComponent?: SearchBoxComponentType;
};

export default function SearchField({
  id,
  searchPlaceholder,
  searchFilters = {},
  setSearchFilters,
  setImplicitSearchFilters,
  clearDropdowns,
  menuStyleSx,
  SearchBoxComponent,
}: Props) {
  const { t } = useTranslation("ssr-client\\components\\search\\SearchField");
  const track = useComponentTrackingContext();

  const {
    getComboboxProps,
    getLabelProps,
    getInputProps,
    getMenuProps,
    getItemProps,
    inputValue,
    setInputValue,
    onInputFocus,
    loadingSuggestions,
    suggestionsOpen,
    searchSuggestions,
    suggestionsHighlightedIndex,
    closeMenu,
  } = useSearchWithSuggestions(
    item => {
      if (setImplicitSearchFilters) {
        setImplicitSearchFilters(queryFromSearchItem(item, searchFilters));
      }
    },
    item => {
      setSearchFilters(queryFromSearchItem(item, searchFilters));
    },
    { ignore: ["teachers"] },
    id
  );

  useEffect(() => {
    setInputValue(searchFilters.q || searchFilters.userName || "");
  }, [setInputValue, searchFilters.q, searchFilters.userName]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        position: "relative",
        width: "100%",
      }}
      {...getComboboxProps()}
    >
      <span style={visuallyHidden} {...getLabelProps()}>
        {t("Search for classes:")}
      </span>
      <ClassSearchForm
        SearchBoxComponent={SearchBoxComponent}
        query={inputValue}
        setQuery={setInputValue}
        placeholder={searchPlaceholder}
        onInputFocus={() => {
          if (clearDropdowns) {
            clearDropdowns();
          }
          onInputFocus();
        }}
        getInputProps={getInputProps}
        onSubmit={query => {
          if (suggestionsOpen) {
            closeMenu();
          }
          if (clearDropdowns) {
            clearDropdowns();
          }
          setSearchFilters(
            queryFromSearchItem(
              {
                type: "keyword",
                name: query || undefined,
                uid: "",
              },
              searchFilters
            )
          );
        }}
        spellCheck={!searchFilters.userUid}
      />
      <SearchSuggestionsMenu
        getMenuProps={getMenuProps}
        isOpen={suggestionsOpen}
        searchSuggestions={searchSuggestions}
        loadingSuggestions={loadingSuggestions}
        highlightedIndex={suggestionsHighlightedIndex}
        getItemProps={getItemProps}
        keyword={inputValue}
        menuStyle={{
          top: "68px",
          minWidth: 291,
          width: "100%",
          zIndex: 1000,
          ...menuStyleSx,
        }}
        track={track}
      />
    </Box>
  );
}
