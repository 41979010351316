import { Box, SxProps } from "@outschool/backpack";
import React from "react";

interface HeaderNavItemProps {
  variant?: "standard" | "transparent";
  sx?: SxProps;
  onClick?: any;
  children?: any;
  [prop: string]: any;
}

function HeaderNavItem(
  { variant = "standard", onClick, sx, children, ...props }: HeaderNavItemProps,
  ref: React.Ref<HTMLDivElement>
) {
  const variantStyles = {
    standard: {
      color: "grey.900",
    },
    transparent: {
      color: "common.white",
    },
  };
  return (
    <Box
      flex
      sx={[
        {
          alignItems: "center",
          paddingY: "12px",
          fontWeight: "fontWeightBold",
          cursor: "pointer",
          transition: "all 0.2s ease",
          whiteSpace: "nowrap",
          position: "relative",
          zIndex: 2,
          ...variantStyles[variant],
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      onClick={onClick}
      ref={ref}
      {...props}
    >
      {children}
    </Box>
  );
}

export default React.forwardRef(HeaderNavItem);
