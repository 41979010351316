import {
  ParentSearchSuggestionsQuery as ParentSearchSuggestionsQueryType,
  ParentSearchSuggestionsQueryVariables,
} from "@outschool/gql-frontend-generated";
import { useLazyQuery } from "@outschool/ui-apollo";
import {
  ParentSearchSuggestionsQuery,
  SearchItem,
} from "@outschool/ui-components-website";
import { useEffect } from "react";

import { useSearchSuggestionsContext } from "../providers/SearchSuggestionsProvider";

export function useSearchSuggestions(size?: number, ignore: string[] = []) {
  const [fetchSuggestions, { data, loading, error }] = useLazyQuery<
    ParentSearchSuggestionsQueryType,
    ParentSearchSuggestionsQueryVariables
  >(ParentSearchSuggestionsQuery, { variables: { query: "", ignore, size } });

  return {
    loading,
    error,
    fetchSuggestions,
    popularTermSuggestions: data?.searchSuggestions?.popularTermSuggestions,
    topicSuggestions: data?.searchSuggestions?.topicSuggestions,
    teacherSuggestions: data?.searchSuggestions?.teacherSuggestions,
  };
}

export function useSetSearchSuggestions({
  popularTermSuggestions,
  topicSuggestions,
  teacherSuggestions,
  setSearchSuggestions,
  inputValue,
}: {
  popularTermSuggestions?: ParentSearchSuggestionsQueryType["searchSuggestions"]["popularTermSuggestions"];
  topicSuggestions?: ParentSearchSuggestionsQueryType["searchSuggestions"]["topicSuggestions"];
  teacherSuggestions?: ParentSearchSuggestionsQueryType["searchSuggestions"]["teacherSuggestions"];
  setSearchSuggestions: (value: React.SetStateAction<SearchItem[]>) => void;
  inputValue?: string;
}) {
  useEffect(() => {
    let suggestions: SearchItem[] = [
      {
        uid: "keyword",
        name: inputValue,
        type: "keyword",
      },
    ];

    topicSuggestions?.forEach(t => {
      suggestions.push({
        uid: t.uid,
        name: t.label,
        type: "topic",
      });
    });

    popularTermSuggestions
      ?.filter(t => !suggestions.some(el => el.name == t.subCategory))
      .forEach(t => {
        suggestions.push({
          uid: t.uid,
          category: t.category,
          name: t.subCategory,
          type: "popular",
        });
      });

    teacherSuggestions?.forEach(t => {
      suggestions.push({
        uid: t.uid,
        name: t.name || "",
        type: "teacher",
        photo: t.photo,
        leaderLink: t.leader_link,
      });
    });
    setSearchSuggestions(suggestions);
  }, [
    topicSuggestions,
    popularTermSuggestions,
    teacherSuggestions,
    inputValue,
    setSearchSuggestions,
  ]);
}

export function useSelectInitialSearchItem({
  selectComboboxItem,
  selectedComboboxItem,
  setInputValue,
}: {
  selectComboboxItem: (item: SearchItem) => void;
  selectedComboboxItem: SearchItem | null;
  setInputValue: (inputValue: string) => void;
}) {
  const searchSuggestionsContext = useSearchSuggestionsContext();
  useEffect(() => {
    if (
      !selectedComboboxItem &&
      searchSuggestionsContext?.initialSelectedItem
    ) {
      const comboboxItemToSelect = searchSuggestionsContext.initialSelectedItem;
      selectComboboxItem(comboboxItemToSelect);
      if (comboboxItemToSelect && comboboxItemToSelect.name) {
        setInputValue(comboboxItemToSelect.name || "");
      }
    }
  }, [
    searchSuggestionsContext,
    selectComboboxItem,
    selectedComboboxItem,
    setInputValue,
  ]);
}
