import { useLookupIP } from "@outschool/iplookup-client";
import { useLocalStorageState } from "@outschool/local-storage";
import {
  CountryCode,
  I18nLocale,
  isSupportedLocale,
  toI18nLocale,
} from "@outschool/localization";
import React from "react";

const countryToLocaleMap = {
  [CountryCode.KR]: I18nLocale.Ko,
  [CountryCode.JP]: I18nLocale.Ja,
  [CountryCode.TW]: I18nLocale.ZhTw,
  [CountryCode.HK]: I18nLocale.ZhTw,
  [CountryCode.MX]: I18nLocale.Es,
  AR: I18nLocale.Es,
  BO: I18nLocale.Es,
  CL: I18nLocale.Es,
  CO: I18nLocale.Es,
  CR: I18nLocale.Es,
  CU: I18nLocale.Es,
  DO: I18nLocale.Es,
  EC: I18nLocale.Es,
  SV: I18nLocale.Es,
  GQ: I18nLocale.Es,
  GT: I18nLocale.Es,
  HN: I18nLocale.Es,
  NI: I18nLocale.Es,
  PA: I18nLocale.Es,
  PY: I18nLocale.Es,
  PE: I18nLocale.Es,
  ES: I18nLocale.Es,
  UY: I18nLocale.Es,
  VE: I18nLocale.Es,
};

const SELECTED_USER_LOCALE_LOCAL_STORAGE_KEY = "selectedUserLocale";

export function useGetInferredLocale(): I18nLocale | null {
  const [inferredLocale, setInferredLocale] = React.useState<I18nLocale | null>(
    null
  );
  const { ipInfo } = useLookupIP();
  const [localStorageLocale] = useLocalStorageState(
    SELECTED_USER_LOCALE_LOCAL_STORAGE_KEY,
    null
  );

  React.useEffect(() => {
    if (localStorageLocale) {
      setInferredLocale(localStorageLocale);
    } else {
      const browserLocale = window?.navigator?.language;
      if (isSupportedLocale(browserLocale)) {
        setInferredLocale(toI18nLocale(browserLocale));
      } else {
        // handle es-Mx as es, ja-JP as ja, etc.
        const [browserLocaleWithoutRegion, _suffix] = browserLocale?.split(
          "-",
          2
        );
        if (isSupportedLocale(browserLocaleWithoutRegion)) {
          setInferredLocale(toI18nLocale(browserLocaleWithoutRegion));
        } else {
          const localeFromIp = countryToLocaleMap[ipInfo?.country?.isoCode];
          if (localeFromIp) {
            setInferredLocale(localeFromIp);
          }
        }
      }
    }
  }, [ipInfo, localStorageLocale]);
  return inferredLocale;
}
