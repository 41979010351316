import { Box } from "@outschool/backpack";
import { isLocalStorageSupported } from "@outschool/local-storage";
import { useTranslation } from "@outschool/localization";
import { Component } from "@outschool/ownership-areas";
import {
  addParamsToUrl,
  joinParentAccountPath,
  joinTeacherAccountPath,
  loginPath,
  teachPath,
} from "@outschool/routes";
import {
  APP_STATE_POST_LOGIN_ACTION_LOCAL_STORAGE_NAME,
  ContinueWithApple,
  ContinueWithGoogle,
  ContinueWithKakao,
  ContinueWithLine,
  LoginUserFeedback,
  SimplifiedEmailSignupForm,
  getPostLoginPath,
  useAuthError,
  useCanShowGoogleAuth,
  useRefreshSessionToken,
  useShouldShowKakao,
  useShouldShowLine,
  useTokenContext,
} from "@outschool/ui-auth";
import { AccountDisclaimer } from "@outschool/ui-components-shared";
import {
  EmailSignupButton,
  switchToLearnerModeWithToken,
  useSimplifyEmailSignupExperiment,
} from "@outschool/ui-components-website";
import { useLinkComponent } from "@outschool/ui-utils";
import { TFunction, Trans } from "next-i18next";
import { useRouter } from "next/router";
import React, { useState } from "react";

import {
  APPLE_SIGNIN_CLIENT_ID,
  GOOGLE_CLIENT_ID,
  isReviewApp,
} from "../../../Env";
import {
  useLoginWithApple,
  useLoginWithRedirect,
} from "../hooks/Authentication";

interface SignupFormProps {
  onRedirect?: (e: any) => void;
  promptLogin?: boolean;
  isTeacherFlow?: boolean;
  trackingParameters?: { [key: string]: string };
  trackingUniqueId?: string;
  setLoadForSimplifyEmailExp?: (isLoading: boolean) => void; // Used in SimplifyEmailSignup experiment
  signupParams?: { [key: string]: string | boolean };
}

export default function SignupForm({
  onRedirect,
  promptLogin,
  isTeacherFlow,
  trackingParameters,
  trackingUniqueId,
  setLoadForSimplifyEmailExp, // Used in SimplifyEmailSignup experiment
  signupParams,
}: SignupFormProps) {
  const Link = useLinkComponent();
  const { t } = useTranslation("ssr-client\\components\\SignupForm");

  // Conditionally show email signup form for SimplifyEmailSignup experiment
  const [showEmailSignupForm, setShowEmailSignupForm] = useState(false);
  return (
    <Box
      sx={{
        display: "grid",
        gap: "1em",
        width: "100%",
      }}
    >
      <SignupButtons
        isTeacherFlow={isTeacherFlow}
        trackingParameters={trackingParameters}
        trackingUniqueId={trackingUniqueId}
        onRedirect={onRedirect}
        signupParams={signupParams}
        // Below parameters used in SimplifyEmailSignup experiment for loading and UI state
        setLoadForSimplifyEmailExp={setLoadForSimplifyEmailExp}
        setShowEmailSignupForm={setShowEmailSignupForm}
        showEmailSignupForm={showEmailSignupForm}
      />
      {promptLogin ? (
        <Trans t={t as TFunction}>
          <Box
            sx={{
              justifySelf: "center",
            }}
          >
            Already have an account?{" "}
            <Link
              data-test-id="login-link"
              onClick={onRedirect}
              to={addParamsToUrl(loginPath(), {
                postLoginPath: getPostLoginPath(),
              })}
              trackingName="login"
              trackingUniqueId={trackingUniqueId}
            >
              Log in
            </Link>
          </Box>
        </Trans>
      ) : null}
      <AccountDisclaimer
        isSignup={true}
        isSimplifyEmailExperimentShown={showEmailSignupForm}
      />
    </Box>
  );
}

function SignupButtons({
  isTeacherFlow,
  trackingParameters,
  trackingUniqueId,
  onRedirect,
  signupParams,
  // Below parameters used in SimplifyEmailSignup experiment
  setLoadForSimplifyEmailExp,
  setShowEmailSignupForm,
  showEmailSignupForm,
}) {
  const Link = useLinkComponent();
  const { setTokens } = useTokenContext();
  const [userFeedbackMessage, setUserFeedbackMessage] =
    React.useState<React.ReactNode>(null);
  const localStorage = isLocalStorageSupported();
  const canShowGoogleAuth = useCanShowGoogleAuth();

  const createParams = {
    isGiftCardSignup: signupParams?.isGiftCardSignup,
    isLeader: isTeacherFlow,
    trackingParameters,
  };

  const handleAuthError = React.useCallback(
    (error, message: React.ReactNode = "Sorry that didn't work, try again") => {
      OsPlatform.captureError(error, {
        tags: { component: Component.UserAccountManagement },
      });
      setUserFeedbackMessage(message);
    },
    [setUserFeedbackMessage]
  );

  const generateLoginError = useAuthError();

  const handleLoginSuccess = React.useCallback(
    async ({
      sessionToken,
      refreshToken,
      postLoginPath,
      isLearnerTransfer,
    }) => {
      if (!isLearnerTransfer) {
        setTokens(sessionToken, refreshToken);

        const redirectPath = isTeacherFlow ? teachPath() : postLoginPath;
        window.history.pushState({}, "", redirectPath);
        window.location.reload();
      } else {
        switchToLearnerModeWithToken({
          isReviewApp,
          transferToken: sessionToken,
          isLearnerLogin: true,
        });
      }
    },
    [isTeacherFlow, setTokens]
  );

  const handleLoginError = React.useCallback(
    error => {
      const errorObject = new Error(error);
      handleAuthError(errorObject, generateLoginError(error));
    },
    [handleAuthError, generateLoginError]
  );

  const handleAppleLogin = useLoginWithApple(
    handleLoginSuccess,
    handleLoginError,
    createParams
  );

  const setAuthStrategy = useLoginWithRedirect(handleLoginError, createParams);

  const showKakao = useShouldShowKakao();
  const showLine = useShouldShowLine();

  /* Code added for SimplifyEmailSignup experiment */
  const { isInTreatment, trigger } = useSimplifyEmailSignupExperiment();
  const router = useRouter();
  const searchParams = router.query;
  const handleSubmitClick = (loading: boolean) => {
    setShowEmailSignupForm(!loading);
  };
  const refreshSessionToken = useRefreshSessionToken();
  /* End of code added for SimplifyEmailSignup experiment */

  return (
    <React.Fragment>
      <LoginUserFeedback
        userFeedbackMessage={userFeedbackMessage}
        isError={true}
      />
      {canShowGoogleAuth && (
        <ContinueWithGoogle
          trackingName="signup-with-google"
          setAuthStrategy={setAuthStrategy}
          clientId={GOOGLE_CLIENT_ID!}
        />
      )}

      {showLine && (
        <ContinueWithLine
          isLearnerApp={false}
          setAuthStrategy={setAuthStrategy}
          trackingName="signup-with-line"
        />
      )}

      {showKakao && (
        <ContinueWithKakao
          isLearnerApp={false}
          setAuthStrategy={setAuthStrategy}
          trackingName="signup-with-kakao"
        />
      )}

      <ContinueWithApple
        onSuccess={handleAppleLogin}
        onError={handleAuthError}
        trackingName="signup-with-apple"
        clientId={APPLE_SIGNIN_CLIENT_ID!}
      />
      {isInTreatment && !isTeacherFlow ? (
        <SimplifiedEmailSignupForm
          trigger={trigger}
          showEmailSignupForm={showEmailSignupForm}
          setShowEmailSignupForm={setShowEmailSignupForm}
          handleSubmitClick={handleSubmitClick}
          setLoadForSimplifyEmailExp={setLoadForSimplifyEmailExp}
          searchParams={searchParams}
          trackingUniqueId={trackingUniqueId}
          onUserLoggedIn={async (
            sessionToken: string,
            refreshToken: string
          ) => {
            await refreshSessionToken();
            setTokens(sessionToken, refreshToken);
            const redirectPath = getPostLoginPath();
            window.history.pushState({}, "", redirectPath);
            window.location.reload();
          }}
          onUserRefresh={() => {
            router.reload();
          }}
        />
      ) : (
        <EmailSignupButton
          linkComponent={Link}
          onClick={() => {
            const postLoginAction = localStorage.getItem(
              APP_STATE_POST_LOGIN_ACTION_LOCAL_STORAGE_NAME
            );
            // temporary hack to set postloginpath & postloginaction in client-side website
            setAuthStrategy(
              "google",
              postLoginAction ? JSON.parse(postLoginAction) : null
            );
            onRedirect();
            trigger(); // SimplifyEmailSignup experiment trigger
          }}
          trackingUniqueId={trackingUniqueId}
          to={
            isTeacherFlow
              ? joinTeacherAccountPath()
              : joinParentAccountPath(signupParams)
          }
        />
      )}
    </React.Fragment>
  );
}
