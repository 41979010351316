import { Button, ButtonProps } from "@outschool/backpack";
import { AuthTrigger, useLoginFlowContext } from "@outschool/ui-auth";

// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */

export default function JoinAndWelcomeButton(props: ButtonProps) {
  const { enterLoginFlow } = useLoginFlowContext();

  return (
    <Button
      variant="contained"
      {...props}
      onClick={() => {
        enterLoginFlow({
          authTrigger: AuthTrigger.JOIN_OUTSCHOOL,
        });
      }}
    />
  );
}
