import OutschoolGlyph from "@/public/_ssr/Outschool_Glyph.svg";
import { Box, Image, Theme } from "@outschool/backpack";
import { useRouter } from "next/router";
import React from "react";

interface HeaderLogoProps {
  isWhiteText?: boolean;
  width?: number;
  height?: number;
}

export default function HeaderLogo({
  isWhiteText,
  width,
  height,
}: HeaderLogoProps) {
  const { pathname } = useRouter();
  // force large logo on homepage
  const forceLargeLogo = ["/[deviceType]"].includes(pathname);

  const Logo = React.useMemo(
    () => LogoImage(isWhiteText, width, height),
    [isWhiteText, width, height]
  );

  return (
    <Box>
      {/* Desktop */}
      <Box
        sx={(theme: Theme) => ({
          display: "flex",

          [theme.breakpoints.down("sm")]: {
            display: forceLargeLogo ? "flex" : "none",
          },
        })}
      >
        {Logo}
      </Box>
      {/* Mobile */}
      <Box
        sx={(theme: Theme) => ({
          display: forceLargeLogo ? "none" : "flex",

          [theme.breakpoints.up("sm")]: {
            display: "none",
          },
        })}
      >
        <Image
          //eslint-disable-next-line i18next/no-literal-string
          alt="Outschool"
          width={42}
          height={42}
          src={OutschoolGlyph.src}
        />
      </Box>
    </Box>
  );
}

function LogoImage(
  isWhiteText: boolean,
  width: number = 206,
  height: number = 26
) {
  const fill = isWhiteText ? "white" : "#4B01D4";

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 2880 355"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_319_3735)">
        <path
          d="M722.5 100H812.5V345H912.5V100H1002.5V10H722.5V100Z"
          fill={fill}
        />
        <path
          d="M1194 138L1156 128C1135.5 122.5 1125 115 1125 106C1125 92.5 1140.5 85 1168.5 85C1203 85 1240.5 98.5 1272.5 122.5V27.5C1235.5 9 1198 0 1158.5 0C1076.5 0 1025 41.5 1025 108C1025 159 1054 192 1112.5 207.5L1161 220.5C1180.5 225.5 1190 233.5 1190 244.5C1190 261 1173 270 1142.5 270C1102.5 270 1061 254.5 1027.5 227V322C1066.5 344 1108 355 1152.5 355C1237 355 1290 311.5 1290 242C1290 189.5 1258 155 1194 138Z"
          fill={fill}
        />
        <path
          d="M592.5 212.5C592.5 240.5 570.5 262.5 542.5 262.5C514.5 262.5 492.5 240.5 492.5 212.5V10H392.5V205C392.5 293 454.5 355 542.5 355C630.5 355 692.5 293 692.5 205V10H592.5V212.5Z"
          fill={fill}
        />
        <path
          d="M180 0C79 0 0 78 0 177.5C0 277 79 355 180 355C281 355 360 277 360 177.5C360 78 281 0 180 0ZM180 262.5C136.5 262.5 102.5 225 102.5 177.5C102.5 130 136.5 92.5 180 92.5C223.5 92.5 257.5 130 257.5 177.5C257.5 225 223.5 262.5 180 262.5Z"
          fill={fill}
        />
        <path d="M2745 255V10H2645V345H2880V255H2745Z" fill={fill} />
        <path
          d="M2432.5 0C2391 0 2361.5 10 2325 36C2303 50 2292 55 2282.5 55C2271 55 2260.5 50 2240 36C2203.5 10 2174 0 2132.5 0C2031.5 0 1952.5 78 1952.5 177.5C1952.5 277 2031.5 355 2132.5 355C2173.5 355 2203 345 2240 319C2260 304.5 2270.5 300 2282.5 300C2295 300 2305.5 304.5 2325 319C2361.5 345 2391 355 2432.5 355C2533.5 355 2612.5 277 2612.5 177.5C2612.5 78 2533.5 0 2432.5 0ZM2432 260C2408.5 260 2373.5 246 2347.5 226.5C2323 208 2303.5 200 2282.5 200C2262 200 2242 208 2217.5 227C2191.5 246.5 2156.5 260 2133 260C2089.5 260 2055 223.5 2055 177.5C2055 131.5 2089.5 95 2133 95C2156.5 95 2191.5 108.5 2217.5 128C2243 147 2263 155 2282.5 155C2303.5 155 2323 147 2347.5 128.5C2373.5 109 2408.5 95 2432 95C2475.5 95 2510 131.5 2510 177.5C2510 223.5 2475.5 260 2432 260Z"
          fill={fill}
        />
        <path
          d="M1820 130H1720V10H1620V345H1720V220H1820V345H1920V10H1820V130Z"
          fill={fill}
        />
        <path
          d="M1310 177.5C1310 281.5 1384.5 355 1490 355C1517.5 355 1552.5 349 1580 339.5V244.5C1557.5 256.5 1532.5 262.5 1505 262.5C1450.5 262.5 1412.5 227.5 1412.5 177.5C1412.5 127.5 1450.5 92.5 1505 92.5C1531 92.5 1556 99 1580 111.5V16.5C1554 6.5 1519.5 0 1490 0C1384.5 0 1310 73.5 1310 177.5Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_319_3735">
          <rect width="2880" height="355" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
