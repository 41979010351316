// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */

import { Search } from "@outschool/business-rules";
import { SearchFilters as SearchFiltersType } from "@outschool/gql-backend-generated";
import { searchPath } from "@outschool/routes";
import { useNavigation } from "@outschool/ui-utils";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import React, { useCallback, useContext } from "react";

import useTrackSearchFiltersApplied from "./useTrackSearchFiltersApplied";

/**
 * Type extended for filters used in the UI but not sent to the backend
 * (userName is always paired with userUid, but allows us to reconstruct
 * the UI)
 */
export type ExtendedSearchFiltersType = SearchFiltersType & {
  userName?: string;
};

export type SetFiltersType = (
  category: string,
  partialFilters: ExtendedSearchFiltersType,
  options?: { overrideRouting?: boolean }
) => void;

interface SearchFiltersContextValue {
  searchUid?: string;
  setFilters?: SetFiltersType;
  filters?: ExtendedSearchFiltersType;
  clearSearchInputBox?: () => void;
  clearSearchInputBoxRef?: React.RefObject<HTMLButtonElement>;
}

export const SearchFiltersContext =
  React.createContext<SearchFiltersContextValue>({});

const clearSearchInputBoxRef = React.createRef<HTMLButtonElement>();

export function SearchFiltersContextProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const navigation = useNavigation();

  const { searchUid, trackSearchFiltersApplied } =
    useTrackSearchFiltersApplied();

  const filters = React.useMemo(() => ({}), []);

  const setFilters = useCallback<SetFiltersType>(
    (filterCategory, partialFilters, options) => {
      if ("q" in partialFilters && "multiTermQuery" in filters) {
        delete filters.multiTermQuery;
      }

      if ("multiTermQuery" in partialFilters && "q" in filters) {
        delete filters.q;
      }
      const newFilters: SearchFiltersType = omitBy(
        Search.omitCatchalls({
          ...filters,
          ...partialFilters,
        }),
        isNil
      );

      const oldFilters = omitBy(Search.omitCatchalls(filters), isNil);

      trackSearchFiltersApplied({ filterCategory, newFilters, oldFilters });

      let newFiltersWithFormattedTheme = Object.assign({}, newFilters);
      if (newFilters.theme) {
        newFiltersWithFormattedTheme.theme = Search.convertThemeFilterToQuery(
          newFilters.theme
        );
      }

      if (!options?.overrideRouting) {
        navigation(searchPath(newFiltersWithFormattedTheme));
      }
    },
    [navigation, filters, trackSearchFiltersApplied]
  );

  const clearSearchInputBox = useCallback(() => {
    clearSearchInputBoxRef.current?.click();
  }, []);

  const value = React.useMemo(
    () => ({
      clearSearchInputBox,
      clearSearchInputBoxRef,
      filters,
      setFilters,
      searchUid,
    }),
    [clearSearchInputBox, filters, setFilters, searchUid]
  );
  return (
    <SearchFiltersContext.Provider value={value}>
      {children}
    </SearchFiltersContext.Provider>
  );
}

export function useSearchFilters() {
  const context = useContext(SearchFiltersContext);
  if (!context.setFilters) {
    throw new Error("Called useSearchFilters without a SearchFiltersContext");
  }
  return context;
}
